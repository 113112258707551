<template>
  <div :class="`${prefixCls}-introduction`" id="aochenIntro">
    <Separate title="公司简介" desc="COMPANY PROFILE" class="wow animate__fadeInUp" data-wow-duration="1s"></Separate>
    <div
        :class="`${prefixCls}-introduction-content wow animate__fadeInUp`"
        data-wow-duration="2s"
        data-wow-delay="0.5s"
    >
      <img src="@/assets/image/home/jinao.png" alt="">
      <div class="company-introduction-desc">
        <div class="desc">
          <p>随着舟山海洋经济快速发展，海岛旅游产业的迅速集聚，普陀区政府审时度势，，联合舟山普陀交通投资发展集团有限公司、舟山六横海钓产业发展有限公司、舟山市普陀区渔港建设管理有限公司共同组建舟山金鳌海洋集团有限公司,并成立全资子公司:舟山普陀金鳌船艇管理服务有限公司,负责全区小型船艇纳管后的安全管理工作。按照市场化运行公司化管理、产业链融合的经营理念，打造普陀小型船艇全产业链中心，这是我市乃至全省的首创。</p>
          <p>公司于2023年06月注册成立，下设安全管理、综合业务等部门，下辖纳管小型船艇500余艘。首要任务是对全区小型船艇管理负主体责任，建立健全精细化小型船艇安全管理体制机制和制度体系，助力型船艇母港基地建设，完善八型船艇维修、保养、停泊、加油、海钓赛事、教育培训等基础设施配套服务功能。</p>
          <p>公司将严格按照“只增不减、总量控制、规范运营”原则，构建“制度规范、管理顺畅、权责清晰、监管有效”的小型船艇管理体系，充分运用“浙里钓”数据化平台推进全区小型船艇组织化、规范化运行。</p>
          <p>配合集团公司积极探索创新，规范海钓产业市场行为，落实海洋生态环境保护及海洋渔业资源修复工作，推动普陀国际海钓公园建设以及当地海洋文化和海洋生态的可持续性融合发展,打造小型船艇公司化管理的“普陀样板”。</p>
        </div>
        <div class="func">
          <div class="card-box">
            <div class="img-card" v-for="item in imgList" :key="item.id" @click="handleGo2(item)">
              <img :src="item.icon" alt="" />
              <span class="labelCn">{{ item.labelCn }}</span>
              <span class="labelEn">{{ item.labelEn }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Separate from '../../../components/Separate/index'
import { WowMixin } from '@/mixin/wow.js'
export default {
  name: 'HomeComponentsIntroduction',
  data () {
    return {
      prefixCls: this.$prefix + '-home',
      funcList: [
        {
          id: 1,
          labelCn: '企业文化',
          labelEn: 'Enterprise Culture'
        },
        {
          id: 2,
          labelCn: '社会责任',
          labelEn: 'Social Responsibility'
        }, {
          id: 3,
          labelCn: '企业荣誉',
          labelEn: 'Enterprise Honor'
        }
      ],
      imgList: [
        {
          id: 1,
          labelCn: '企业文化',
          labelEn: 'Enterprise Culture',
          href: 'aochenCulture',
          icon: require('@/assets/image/home/culture.png'),
          path: '/aochen/culture'
        },
        {
          id: 2,
          labelCn: '社会责任',
          labelEn: 'Social Responsibility',
          href: 'aochenSocial',
          icon: require('@/assets/image/home/social.png'),
          path: '/aochen/social'
        },
        {
          id: 3,
          labelCn: '资质荣誉',
          labelEn: 'Qualification Honor',
          href: 'aochenHonors',
          icon: require('@/assets/image/home/honor.png'),
          path: '/aochen/honors'
        }
      ]
    }
  },
  mixins: [WowMixin],
  components: {
    Separate
  },
  methods: {
    handleGo2 ({ path, href }) {
      this.$router.push({ path })
      setTimeout(() => {
        document.querySelector(`#${href}`).scrollIntoView({
          behavior: 'smooth'
        })
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
$prefixCls: $namespace + -home-introduction;
.#{$prefixCls}{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: $content-width;
  padding-bottom: 50px;
  overflow: hidden;
  &-content{
    width: 100%;
    //height: 00px;
    word-spacing: 14px;
    font-size: 14px;
    line-height: 2;
    text-align: left;
    color: #707B7C;
    img {
      width: 100%;
    }
    .company-introduction-desc {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      //height: 100%;
      .desc {
        font, p, span {
          font-family: arial !important;
          font-size: 16px !important;
          color: #191818 !important;
          line-height: 40px !important;
          font-weight: lighter !important;
          text-indent: 2em;
        }
      }
      .card-box {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .img-card {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          img {
            width: 40px;
            height: 46px;
          }
          .labelCn {
            color: #666;
            font-size: 14px;
            margin: 3px 0;
          }
          .labelEn {
            color: #999;
            font-size: 14px;
            word-spacing: 0px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
